export const PictureLevels = Object.freeze({
	NORMAL: Object.freeze({
	  name: '通常',
	  value: 0,
	}),
	WARNING: Object.freeze({
	  name: '警告',
	  value: 1,
	}),
	EMERGENCY: Object.freeze({
	  name: '緊急',
	  value: 2,
	}),
});