import { useState, useEffect } from 'react';
import { Box, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { AccountCircle, Comment, ModeEdit as EditIcon } from '@mui/icons-material';
import { LoadingButton } from '../components/exports';
import { PictureLevels } from '../constants/exports';

import { authManager, taxiPictureApi } from '../models/exports';

const styles = {
	imgContainer: {
		width: `100%`,
		height: `200px`,
		marginTop: '10px',
	},

	img: {
		position: 'relative',
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	}
};

function getDateValue(d) {
  const year = d.getFullYear();
  const month = `0${d.getMonth() + 1}`.slice(-2);
  const date = `0${d.getDate()}`.slice(-2);

  return `${year}-${month}-${date}`;
}

export function EditPage({ location }) {
	const pictureInfo = location.state.pictureInfo;
	const [comment, setComment] = useState(pictureInfo.comment);
	const [level, setLevel] = useState(pictureInfo.level);
	const [loading, setLoading] = useState(false);

	const [endDt, setEndDt] = useState((() => {
		if (!pictureInfo.enddt) {
			return '';
		}

		return getDateValue(new Date(pictureInfo.enddt));
	})());

	const submit = async e => {
		e.preventDefault();

		setLoading(true);

		await (async () => {
			const companyId = await authManager.getCompanyId();

			if (companyId === null && isNaN(parseInt(companyId))) {
				alert('エラーが発生しました');
				return;
			}

			const request = {
				startdt: pictureInfo.startdt,
				enddt: endDt ? new Date(`${endDt}T23:59:59+09:00`) : null,
				comment: comment,
				level: level,
				delflg: 0,
			}

			const [, err] = await taxiPictureApi.put(`/pictures/${companyId}/${pictureInfo.pictureid}`, request);
			if (err) {
				alert('失敗')
			} else {
				alert('成功')
			}
		})();

		setLoading(false);
	}

	return (
		<div>
			<div style={{ display: 'flex' }}>
				<EditIcon />
				<span style={{ padding: '2px' }}>編集</span>
			</div>
			<div style={styles.imgContainer}>
				<img src={pictureInfo.filelocation} alt="" style={styles.img} />
			</div>

			<form onSubmit={submit}>
				<TextField
					label="投稿者名"
					type="text"
					value={pictureInfo.contributor}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<AccountCircle />
							</InputAdornment>
						),
					}}
					size="small"
					margin="normal"
					variant="standard"
					fullWidth
					disabled />

				<TextField
					label="コメント"
					type="text"
					value={comment}
					onChange={e => setComment(e.target.value)}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Comment />
							</InputAdornment>
						),
					}}
					margin="normal"
					size="small"
					variant="standard"
					fullWidth />

				<Grid container spacing={6}>
					<Grid item xs={7}>
						<TextField
							id="date"
							label="掲載終了日"
							type="date"
							InputLabelProps={{ shrink: true }}
							value={endDt}
							size="small"
							margin="normal"
							variant="outlined"
							fullWidth
							onChange={e => setEndDt(e.target.value)} />
					</Grid>

					<Grid item xs={5}>
						<FormControl size="small" margin="normal" fullWidth>
							<InputLabel id="level-select-label">警告レベル</InputLabel>
							<Select
								value={level}
								labelId="level-select-label"
								id="level-select"
								label="警告レベル"
								variant="outlined"
								onChange={e => setLevel(e.target.value)}>
								{Object.keys(PictureLevels).map((key, i) =>
									<MenuItem
										key={i}
										value={PictureLevels[key].value}>
										{PictureLevels[key].name}
									</MenuItem>
								)}
							</Select>
						</FormControl>

					</Grid>
				</Grid>

				<Box m={4} />

				<LoadingButton loading={loading} fullWidth>変更</LoadingButton>
				<Box m={4} />
			</form>
		</div>
	);
}