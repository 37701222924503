import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { LoadingButton } from '../components/exports';

import { taxiPictureApi, authManager } from '../models/exports';

const Operation = Object.freeze({
  NORMAL: 'normal',
  SELECT: 'select',
});

const contentStyle = {
  margin: '10px 5px 0px',
}

const picturesState = {};

export function GarallyPage({ history }) {
  const PICTURE_MARGIN_PX = 3;
  const CONTENTS_PER_LINE = 3;  // 1行に表示する画像の数
  const content = useRef(null);
  const footer = useRef(null);
  const [footerHeight, setFooterHeight] = useState(0);
  const [pictures, setPictures] = useState([]);
  const [pictureStyle, setPictureStyle] = useState({});
  const [operation, setOperation] = useState(Operation.NORMAL);
  const [loading, setLoading] = useState(false);

  const [selectedNum, setSelectedNum] = useState(0);

  useLayoutEffect(() => {
    const contentWidth = content.current.offsetWidth;
    const picturePx = parseInt((contentWidth - 2 * PICTURE_MARGIN_PX * CONTENTS_PER_LINE) / CONTENTS_PER_LINE);

    setPictureStyle({
      position: 'relative',
      width: `${picturePx}px`,
      height: `${picturePx}px`,
      margin: `${PICTURE_MARGIN_PX}px`,
    });
  }, [])

  useEffect(() => {
    getPictures();
  }, []);


  useEffect(() => {
    if (operation === Operation.NORMAL) {
      Object.values(picturesState).forEach(state => {
        state.selected = false;
        setSelectedNum(0);
      })
    }
    else if (operation === Operation.SELECT) {
      setFooterHeight(footer.current.offsetHeight);
    }
  }, [operation]);

  const getPictures = async () => {
    const companyId = await authManager.getCompanyId();
    if (companyId === null) {
      return;
    }

    const path = `/pictures/${companyId}?status=all`;

    const [response, err] = await taxiPictureApi.get(path);
    if (err !== null) {
      alert('エラーが発生しました')
      return;
    }

    console.log(response);

    // Geolocation APIを使う兼ね合いでhttpsにしないと画像の表示ができないのでproxyサーバーに向き先を変える
    if (process.env.REACT_APP_ENV === 'local') {

      const {
        REACT_APP_REPLACE_LOCAL_S3_URL_FROM: from,
        REACT_APP_REPLACE_LOCAL_S3_URL_TO: to
      } = process.env;

      response.data.forEach(p => {
        p.filelocation = p.filelocation.replace(from, to);
      });
    }

    if (content.current == null) {
      return;
    }

    const arr = [];

    for (let i in response.data) {
      if (i % CONTENTS_PER_LINE === 0) arr.push([]);
      arr[parseInt(i / CONTENTS_PER_LINE)].push(response.data[i]);

      picturesState[response.data[i].pictureid] = {
        selected: false,
      }
    }

    setPictures(arr);
  }

  const deletePictures = async () => {
    setLoading(true);
    await (async () => {
      const companyId = await authManager.getCompanyId();
      if (companyId === null) {
        return;
      }
      const ids = Object.entries(picturesState).filter(p => p[1].selected)
        .map(p => p[0])
        .join(',');

      const path = `/pictures/${companyId}?pictureids=${ids}`;
      const [response, err] = await taxiPictureApi.delete(path)

      if (err != null) {
        alert('エラーが―発生しました');
      } else {
        await getPictures();
        setOperation(Operation.NORMAL)
      }
    })();
    setLoading(false);
  };


  function PictureItem({ pictureInfo }) {
    const [selected, setSelected] = useState(picturesState[pictureInfo.pictureid].selected);
    console.log(picturesState[pictureInfo.pictureid].selected)
    return (() => {
      console.log('render')

      const imgStyle = {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '4px',
        zIndex: 1,
      }

      const selectedStyle = {
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: 'black',
        opacity: 0.5,
        borderRadius: '4px',
        zIndex: 2,
      }

      const checkedStyle = {
        position: 'absolute',
        top: '3%',
        left: '3%',
        zIndex: 3,
      }

      const onClick = () => {
        if (operation === Operation.NORMAL) {
          history.push({ pathname: '/edit', state: { pictureInfo: pictureInfo } });
        }
        else if (operation === Operation.SELECT) {
          const value = !selected;

          picturesState[pictureInfo.pictureid].selected = value;
          setSelected(value);
          setSelectedNum(Object.values(picturesState).filter(state => state.selected).length);
        }
      };

      return (
        <Box container style={pictureStyle} onClick={onClick} >
          <img src={pictureInfo.filelocation} alt="" style={imgStyle} />
          {(Operation.SELECT && selected) && <CheckCircleIcon color="error" variant="contained" style={checkedStyle} />}
          {(Operation.SELECT && selected) && <div style={selectedStyle}></div>}
        </Box>
      );
    })();
  }

  function Footer() {
    const button = useRef(null);
    const text = useRef(null);
    const [buttonStyle, setButtonStyle] = useState({});
    const [textStyle, setTextStyle] = useState({});

    const footerStyle = {
      position: 'fixed',
      background: 'black',
      bottom: 0,
      width: '100%',
      height: '50px',
      zIndex: 100,
    };

    useLayoutEffect(() => {
      setButtonStyle({
        position: 'absolute',
        right: '3%',
        top: (50 - button.current.offsetHeight)/2,
        zIndex: 2,
      });

      setTextStyle({
        position: 'absolute',
        left: '10%',
        fontSize: '1.1rem',
        top: (50 - text.current.offsetHeight)/2,
        color: 'white',
        zIndex: 1,
      });
    }, []);

    return (
      <>
        <div style={footerStyle} ref={footer}>
          <span ref={text} style={textStyle}>{selectedNum > 0 ? `${selectedNum}枚の写真を選択中` : '項目を選択'}</span>
          <LoadingButton elm={button} loading={loading} color="error" variant="contained" onClick={deletePictures} style={buttonStyle} endIcon={<DeleteIcon />}>削除</LoadingButton>
        </div>
        <div style={{ paddingBottom: footerHeight }} />
      </>
    )
  }

  return (
    <div>
      <div ref={content} style={contentStyle}>
        <Box m={2} />
        <div style={{ position: 'fixed', right: '3%', zIndex: 10 }} >
          {operation === Operation.NORMAL
            ? (
              <Button
                variant="contained"
                style={{ background: grey[500], borderRadius: '16px' }}
                onClick={() => setOperation(Operation.SELECT)}
                size="small">
                選択
              </Button>
            ) : operation === Operation.SELECT ? (
              <Button
                variant="contained"
                style={{ background: grey[500], borderRadius: '16px' }}
                onClick={() => setOperation(Operation.NORMAL)}
                size="small">
                キャンセル
              </Button>
            ) : (
              <div />
            )
          }
        </div>
        {pictures.map((line, i) =>
          <Grid container key={i}>
            {line.map((p, i) =>
              <Grid item key={i}>
                <PictureItem pictureInfo={p} />
              </Grid>)}
          </Grid>
        )}

      </div>
      {operation === Operation.SELECT && <Footer />}
    </div>
  )
}
