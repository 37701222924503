import { Button, CircularProgress } from '@mui/material';

export function LoadingButton({
	elm = null,
	loading = false,
	type = "submit",
	variant = "contained",
	size = "large",
	color = "primary",
	fullWidth = false,
	style = {},
	endIcon = <div />,
	onClick = e => null,
	children,
}) {
	return (
		<Button
			ref={elm}
			type={type}
			variant={variant}
			size={size}
			color={color}
			disabled={loading}
			endIcon={endIcon}
			style={style}
			onClick={onClick}
			fullWidth={fullWidth}>
			{ loading ? <CircularProgress color="secondary" size="1.6rem" /> : children }
		</Button>
	);
}