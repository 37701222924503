import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    AppBar,
    Box,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { authManager } from '../models/exports'

export function WithAppBarLayout({ children, handleSignOut = f => f }) {
    const appBar = useRef(null)
    const [anchorEl, setAnchorEl] = useState(null);
    const [appBarHeight, setAppBarHeight] = useState(0);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setAppBarHeight(appBar.current.offsetHeight);
    }, []);

    async function signOut() {
        await authManager.singOut();
        handleSignOut();
    }

    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="fixed" ref={appBar}>
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={handleClick}
                            sx={{ mr: 2 }}>
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}>
                            <MenuItem onClick={handleClose}><Link to="/"><p>投稿</p></Link></MenuItem>
                            <MenuItem onClick={handleClose}><Link to="/garally">一覧</Link></MenuItem>
                            <MenuItem onClick={signOut}>ログアウト</MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
            </Box>
            <div style={{paddingTop: appBarHeight}}></div>
            {children}
        </div>
    );
}
