import { Auth } from 'aws-amplify';

export const authManager = (() => {
	const privates = {
		user: null,
	};

	const publics = {
		async getUser() {
			if (privates.user === null) {
				try {
					privates.user = await Auth.currentAuthenticatedUser();
				} catch (ignore) { }
			}

			return privates.user;
		},

		async getCompanyId() {
			const user = await this.getUser();
			if (user === null) return null;

			const companyId = user.attributes['custom:companyid'];

			if (!companyId || isNaN(parseInt(companyId))) {
				return null;
			}

			return companyId;
		},

		async isLoggedIn() {
			return (await this.getUser()) !== null;
		},

		async signIn(username, password) {
			try {
				const user = await Auth.signIn(username, password);
				privates.user = user;

				return [user, null];
			} catch (err) {
				return [null, err]
			}
		},

		async singOut() {
			try {
				await Auth.signOut();
				privates.user = null;
			} catch (err) {
				console.log('error signing out: ', err);
			}
		},

		async completeNewPassword(newPassword) {
			try {
				const user = await Auth.completeNewPassword(privates.user, newPassword);
				privates.user = user;

				return [user, null];
			} catch (err) {
				console.log('error signing in', err);
				return [null, err]
			}
		},
	}

	return publics;
})();
