import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import {
  EditPage,
  GarallyPage,
  SignInPage,
  UploadPage,
  WithAppBarLayout,
} from './pages/exports';

import { authManager } from './models/exports'

export default function App() {
  const [signedIn, setSignedIn] = useState(null);

  useEffect(() => {
    checkUser();
  }, []);

  const checkUser = async () => {
    setSignedIn(await authManager.isLoggedIn());
  }

  return signedIn === null ? <div />
    : signedIn ? (
      <Router>
        <Switch>
          <WithAppBarLayout handleSignOut={() => setSignedIn(false)}>
            <Route exact path='/' component={UploadPage} />
            <Route path='/garally' component={GarallyPage} />
            <Route path='/edit' component={EditPage} />
          </WithAppBarLayout>
        </Switch>
      </Router>
    ) : (
      <SignInPage handleSignIn={() => setSignedIn(true)} />
    );
}
