import { useState } from 'react';
import { CameraIcon } from '../images/exports';

const style = {
    container: {
        position: `relative`,
        // marginBottom: `var(--margin-bottom, 10px)`,
        backgroundColor: `var(--background-color)`,
        padding: `var(--padding, 35px 40px)`,
        textAlign: `left`,
        display: `inline-block`,
        borderRadius: `var(--border-radius, 6px)`,
        // -webkit-box-sizing: border-box;
        boxSizing: `border-box`,
        fontFamily: `var(--font-family)`,
        width: `100%`,
        /* min-width: var(--min-width, 20rem); */
    },

    picker: {
        position: `relative`,
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
        border: `2px solid gray`,
        borderStyle: `dotted`,
    },

    placeholder: {
        width: '100%',
        height: '100%',
        position: `absolute`,
        textAlign: 'center',
        fontWeight: `bold`,
        top: '30%'
    },

    inputFile: {
        width: '100%',
        height: '100%',
        display: 'inline-block',
        position: 'absolute',
        left: '0px',
        top: '0px',
        opacity: 0,
        cursor: 'pointer',
    },

    iconCamera: {
        width: '60px',
        height: '60px',
    },

    preview: {
        width: '100%',
        height: '200px',
        margin: '10px',
        objectFit: 'cover',
        border: 'none',
    },
}

function generateId() {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    return Array.from(
        Array(128)).map(() => charset[Math.floor(Math.random() * charset.length)]).join('');
}

export function ImagePicker({ name = '', onChange = f => true, capture = false, required = false }) {
    const id = `image-picker-${generateId()}`;
    const [file, setFile] = useState(null);

    const setPreview = file => {
        const component = document.getElementById(id);
        const reader = new FileReader();
        reader.onload = (function () {

            component.getElementsByClassName('preview')[0].setAttribute('src', reader.result);
            setFile(file);
        });
        reader.readAsDataURL(file);
    }

    const removePreview = () => {
        const component = document.getElementById(id);
        component.getElementsByClassName('upload-file')[0].value = '';
        component.getElementsByClassName('preview')[0].removeAttribute('src');
        setFile(null);
    }

    const onImageChanged = e => {
        const file = e.target.files[0];

        if (file === null || file === undefined) {
            return;
        }

        const finishAction = (b) => b === false ? removePreview() : setPreview(file);

        const result = onChange(e);

        if (result instanceof Promise) {
            result.then(result => {
                finishAction(result);
            });
        } else {
            finishAction(result);
        }
    }

    return (
        <div id={id}>
            <div style={style.container}>
                <div style={style.picker}>
                    {file === null && (
                        <div style={style.placeholder}>
                            <img src={CameraIcon} alt="" />
                            <div>写真を撮影してください</div>
                        </div>
                    )}
                    <input
                        className="upload-file"
                        type="file"
                        name={name}
                        style={style.inputFile}
                        accept="capture=camera"
                        required={required && file === null}
                        onChange={onImageChanged}
                        capture={capture} />
                    <img className="preview" style={style.preview} alt="" />
                </div>
            </div>
        </div>
    )
}
