import axios from 'axios';


export class ApiClient {
    constructor ({baseURL='', customHeader = async f => null}) {
        this.baseURL = baseURL;
        this.customHeader = customHeader;
    }

    async makeHeader(header) {
        return Object.assign({
            'Content-Type': 'application/json'
        }, (await this.customHeader()), header);
    }

    get(path, body={}, header={}) {
        return new Promise(async (resolve, _) => {
            const config = {
                params: body,
                headers: await this.makeHeader(header),
            };
            try {
                const response = await axios.get(this.baseURL + path, config);
                resolve([response.data, null]);
            }
            catch (e) {
                console.error(e);
                resolve([null, e]);
            }
        });
    }

    post(path, body={}, header={}) {
        return new Promise(async (resolve, _) => {
            const config = {
                headers: await this.makeHeader(header),
            };
            try {
                const response = await axios.post(this.baseURL + path, body, config);
                resolve([response.data, null]);
            }
            catch (e) {
                console.error(e);
                resolve([null, e]);
            }
        });
    }

    put(path, body={}, header={}) {
        return new Promise(async (resolve, _) => {
            const config = {
                headers: await this.makeHeader(header),
            };
            try {
                const response = await axios.put(this.baseURL + path, body, config);
                resolve([response.data, null]);
            }
            catch (e) {
                console.error(e);
                resolve([null, e]);
            }
        });
    }

    delete(path, body={}, header={}) {
        return new Promise(async (resolve, _) => {
            const config = {
                headers: await this.makeHeader(header),
                params: body,
            };
            try {
                const response = await axios.delete(this.baseURL + path, config);
                resolve([response.data, null]);
            }
            catch (e) {
                console.error(e);
                resolve([null, e]);
            }
        });
    }
}