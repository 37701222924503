import { ApiClient } from './api-client';
import { Auth } from 'aws-amplify';

export const taxiPictureApi = new ApiClient({
	baseURL: process.env.REACT_APP_PICTURE_API_URL,
	customHeader: async () => {
		return {
			Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
		}
	}
});