import { useState } from 'react';

import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import { ImagePicker, LoadingButton } from '../components/exports';
import EXIF from 'exif-js';
import { AccountCircle, Comment } from '@mui/icons-material';

import { authManager, taxiPictureApi } from '../models/exports';
import { PictureLevels, LatLonTypes } from '../constants/exports';

const requestTemplate = {
  filedata: '',                       // base64文字列の画像データ
  basefilename: '',                   // ファイル名
  companyid: '',                      // 新視令ユーザーID
  enddt: '',                          // 掲載終了日時(タイムゾーン有)
  comment: '',                        // コメント
  contributor: '',                    // 投稿者名
  lat: 0,                             // 緯度(世界測地系)
  lon: 0,                             // 経度(世界測地系)
  latlontype: LatLonTypes.UNKNOWN,    // 座標取得種別 (1=EXIF 2=GPS 3=その他)
  level: PictureLevels.NORMAL.value,  // 写真種別
  contributiondt: '',                 // 撮影日時
}

const contentStyle = {
    margin: '10px 15px 0px',
}

function getExifdata(file) {
  return new Promise((resolve, _) => {
    EXIF.getData(file, () => {
      resolve(file.exifdata);
    });
  });
}

function getLatLng(GPSLatitude, GPSLongitude) {
  // 世界測地に変換
  const calc = o =>
    (o[0].numerator / o[0].denominator) +
    (o[1].numerator / o[1].denominator) / 60 +
    (o[2].numerator / o[2].denominator) / 3600;

  return [calc(GPSLatitude), calc(GPSLongitude)];
}

function compressImage(file) {
  return new Promise((resolve, _) => {
    const LONG_SIDE_PIXEL = 500;
    // 画像をリサイズする
    const image = new Image();
    const reader = new FileReader();

    reader.onload = e => {
      image.onload = () => {
        const [width, height] = (() => {
          if (image.width > image.height) {
            const ratio = image.height / image.width;
            return [LONG_SIDE_PIXEL, LONG_SIDE_PIXEL * ratio];
          } else {
            const ratio = image.width / image.height;
            return [LONG_SIDE_PIXEL * ratio, LONG_SIDE_PIXEL];
          }
        })();

        const canvas = document.getElementById('canvas')

        if (canvas === null) {
          return;
        }

        canvas.setAttribute('width', `${width}px`);
        canvas.setAttribute('height', `${height}px`);

        const ctx = canvas.getContext('2d');

        ctx.clearRect(0, 0, width, height);
        ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, width, height);

        const base64 = canvas.toDataURL('image/jpeg').replace(/^.*,/, '');
        resolve(base64);
      }
      const result = e.target.result;
      image.setAttribute('src', result);
    }
    reader.readAsDataURL(file);
  });
}

export function UploadPage() {
  const [request, setRequest] = useState(() => Object.assign({}, requestTemplate))
  const [loading, setLoading] = useState(false);

  async function onImageChanged(e) {
    const reset = () => {
      request.filedata = requestTemplate.filedata;
      request.basefilename = requestTemplate.basefilename;
      request.lat = requestTemplate.lat;
      request.lon = requestTemplate.lon;
      request.contributiondt = requestTemplate.contributiondt;
    };


    const file = e.target.files[0];

    if (file === undefined) {
      reset();
      return false;
    }

    const locationInfo = await getLocationFromDevice();

    if (locationInfo === null) {
      alert('位置情報を取得できません');
      reset();
      return false;
    }

    try {
      request.filedata = await compressImage(file);
    } catch (e) {
      reset();
      return false;
    }

    request.basefilename = file.name;
    request.lat = locationInfo.latitude;
    request.lon = locationInfo.longitude;
    request.latlontype = locationInfo.latlontype;
    request.contributiondt = locationInfo.contributiondt;

    return true;
  }

  const getLocationFromDevice = async () => {
    if (!window.navigator.geolocation) {
      alert('位置情報の使用を許可してください');
      return null;
    }

    const coords = await (async () =>
      new Promise((resolve, _) => {
        window.navigator.geolocation.getCurrentPosition(pos => {
          resolve(pos.coords);
        }, err => {
          console.log(err)
          resolve(null)
        }, {
          enableHighAccuracy: true,  // 精度を上げるオプション(処理時間はかかるようになる)
          timeout: 5000,
          maximumAge: 0  // 位置情報の最大キャッシュ時間(キャッシュしない)
        });
      })
    )();

    if (coords === null) {
      return null;
    }

    return {
      latitude: coords.latitude,
      longitude: coords.longitude,
      latlontype: LatLonTypes.GPS,
      contributiondt: new Date(),
    };
  }

  const getLocationFromExif = async file => {
    alert('from exif')
    const exifdata = await getExifdata(file);
    console.log(exifdata)

    const { GPSLatitude, GPSLongitude, DateTimeOriginal } = exifdata;

    if (!GPSLatitude || !GPSLongitude || !DateTimeOriginal) {
      return null;
    }

    const [latitude, longitude] = getLatLng(GPSLatitude, GPSLongitude);

    console.log(`${latitude}, ${longitude}`);

    if (latitude * longitude === 0) {
      return null;
    }

    // 備考: タイムゾーンの情報はなし
    // '年:月:日 時:分:秒'の形式になってるので'年-月-日 時:分:秒'にする
    const d = DateTimeOriginal.split(' ');
    d[0] = d[0].replaceAll(':', '-');

    return {
      latitude: latitude,
      longitude: longitude,
      latlontype: LatLonTypes.EXIF,
      contributiondt: new Date(d.join(' ')),
    };
  }

  async function onUploadClick(e) {
    e.preventDefault();
    console.log(request)

    setLoading(true);
    await (async () => {
      // 掲載日が指定されてなければnullにする
      if (!request.enddt) {
        request.enddt = null;
      }

      const companyId = await authManager.getCompanyId();

      if (companyId === null) {
        alert('エラーが発生しました');
        return;
      }

      request.companyid = parseInt(companyId);

      const [, err] = await taxiPictureApi.post('/pictures', request);
      if (err) {
        alert('失敗')
      } else {
        alert('成功')
      }
    })();
    setLoading(false);
  }

  return (
    <div style={contentStyle}>
      {/* 画像圧縮用のCanvas */}
      <div style={{ display: 'none' }}>
        <canvas id="canvas" width="0" height="0" />
      </div>
      <form onSubmit={onUploadClick}>
        <TextField
          label="投稿者名"
          type="text"
          value={request.contributor}
          onChange={e => setRequest({ ...request, contributor: e.target.value })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          size="small"
          margin="normal"
          required
          variant="standard"
          fullWidth />

        <TextField
          label="コメント"
          type="text"
          value={request.comment}
          onChange={e => setRequest({ ...request, comment: e.target.value })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Comment />
              </InputAdornment>
            ),
          }}
          size="small"
          variant="standard"
          fullWidth />

        <Box m={1} />
        <Grid container spacing={6}>
          <Grid item xs={7}>
            <TextField
              id="date"
              label="掲載終了日"
              type="date"
              InputLabelProps={{ shrink: true }}
              onChange={e => setRequest({ ...request, enddt: new Date(`${e.target.value}T23:59:59+09:00`) })}
              size="small"
              margin="normal"
              variant="outlined"
              fullWidth />
          </Grid>

          <Grid item xs={5}>
            <FormControl size="small" margin="normal" fullWidth>
              <InputLabel id="level-select-label">警告レベル</InputLabel>
              <Select
                value={request.level}
                labelId="level-select-label"
                id="level-select"
                label="警告レベル"
                variant="outlined"
                onChange={e => setRequest({ ...request, level: e.target.value })}>
                {Object.keys(PictureLevels).map((key, i) =>
                  <MenuItem
                    key={i}
                    value={PictureLevels[key].value}>
                    {PictureLevels[key].name}
                  </MenuItem>
                )}
              </Select>
            </FormControl>

          </Grid>

        </Grid>

        <ImagePicker name="upload-image" onChange={onImageChanged} capture required />

        <LoadingButton
          loading={loading}
          fullWidth>
          アップロード
        </LoadingButton>
        <Box m={4} />
      </form>
    </div>
  );
}
